import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SelectInfiniteScrollSpicService } from './services/select-infinite-scroll-spic.service';
import { ToastrService } from 'ngx-toastr';
import { SelectInfiniteScrollSpicInterface } from 'src/utils/models/select.interface';
import { RequestAttribute } from 'src/utils/models/http.interface';
import { isOnline, isOnlineEmitter } from 'src/utils/functions/utilities.service';
import { ButtonInduxtry } from '@induxtry/library/utils/models/input.interface';
import mockState from '../../../../utils/constants/mocks/common/components/options-state.mock.json';
import mockType from '../../../../utils/constants/mocks/common/components/type-filter.mock.json';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'select-infinite-scroll-spic',
  templateUrl: './select-infinite-scroll-spic.component.html',
  styleUrls: ['./select-infinite-scroll-spic.component.scss']
})
export class SelectInfiniteScrollSpicComponent implements OnInit, OnChanges {
  @Input() selectInterface: SelectInfiniteScrollSpicInterface;
  @Input() changeSelect: number;
  @Output() valueSelected: EventEmitter<any> = new EventEmitter();
  @Input() disabledAll: boolean = false;
  @ViewChild('multipleSelectInfiniteScrollSpicInput') multipleSelectInfiniteScrollSpicInput: MatSelect;

  valueSelect;
  filterSelect: string;
  options: any[];
  params: RequestAttribute[] = [];
  online = isOnline();
  emitterOnline = isOnlineEmitter();
  beforeValue;
  loading: boolean;
  buttonClear: ButtonInduxtry;
  ignoreKeyUp = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'];
  allOptions = false;

  constructor(
    private readonly selectInfiniteScrollSpicService: SelectInfiniteScrollSpicService,
    private readonly toastr: ToastrService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elRef: ElementRef<HTMLElement>,
  ) { }

  ngOnInit() {
    this.buttonClear = { label: 'LIMPAR', customStyle: 'secondary', showLabel: true }
    this.valueSelect = this.selectInterface.value;
    this.beforeValue = this.selectInterface.value;
    this.params = [...this.selectInterface.attributes];
    this.filterSelect = this.selectInterface.searchValue || '';

    if (this.valueSelect) {
      if (this.selectInterface.mockData) {
        this.options = this.selectInterface.mockData.rows.filter(res => res[this.selectInterface.paramName] === this.valueSelect);
      } {
        const opt = { _id: null }
        opt[this.selectInterface.paramName] = this.valueSelect;
        this.options = [opt];
      }
      this.getDataSelect(this.filterSelect);
    }

    this.emitterOnline.subscribe(state => {
      this.online = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  returnValueTrigger(field) {
    if (this.selectInterface?.type === 'boolean' || this.selectInterface?.type === 'state' || this.selectInterface?.type === 'type') {
      return this.returnValueOption(field, this.selectInterface.type)
    } else {
      return field;
    }
  }

  getValueTrigger(fields) {
    const arrayFields = [];
    if (fields) {
      fields?.forEach(field => {
        arrayFields.push(this.returnValueTrigger(field));
      });
    }
    return arrayFields.length > 0 ? arrayFields.splice(0, (this.selectInterface?.max || 1)): [];
  }

  getCountTrigger(fields) {
    return fields?.length ? (fields.length - (this.selectInterface?.max || 1)) : 0;
  }

  verifyEmptyOptions(options): boolean {
    if (options?.length > 0) {
      let countOpt = options.length;
      options.forEach(opt => {
        if (opt[this.selectInterface.paramValue] === null || opt[this.selectInterface.paramValue] === '') {
          countOpt--;
        }
      });
      return countOpt <= 0 ? true : false;
    } else {
      return true;
    }
  }

  ngOnChanges(change) {
    if (change?.changeSelect && !change.changeSelect.firstChange) {
      this.valueSelect = this.selectInterface.value;
    }
  }

  openedSelect() {
    this.elRef.nativeElement.classList.toggle('focus-open-select-spic');
  }

  handleOpenSelect(event): void {
    if (event) {
      this.getDataSelect(this.filterSelect);
    } else if(this.options?.filter(res => res[this.selectInterface.paramValue] === this.valueSelect).length <= 0) {
      this.getDataSelect(this.valueSelect);
    }
  }

  getDataSelect(value) {
    this.params = [...this.selectInterface.attributes];
    this.loading = true;
    return new Promise((resolve) => {
      this.selectInfiniteScrollSpicService.listDataSelect(
        this.selectInterface.endpoint,
        value,
        this.selectInterface.paramName,
        this.params,
        this.selectInterface?.mockData
      ).subscribe((res: any) => {
        if (this.online) {
          this.options = (res?.rows ? res.rows : res.data);
        } else {
          this.options = this.selectInfiniteScrollSpicService.listDataSelectOffline(res, value, this.selectInterface.paramName, this.params, this.selectInterface?.mockData);
        }
        this.loading = false;
        const indexParam = this.params.findIndex(res => res.param === this.selectInterface.paramName);
        if (indexParam !== -1 && this.params[indexParam].value !== this.beforeValue) {
          this.params[indexParam].value = this.valueSelect;
        }

        resolve(true);
      }, (error) => {
        this.options = [];
        this.loading = false;
        this.toastr.show('Erro ao buscar opções!', 'Erro ao buscar!', {
          timeOut: 5000,
          messageClass: 'error',
          titleClass: JSON.stringify({})
        });
        resolve(true);
      });
    })
  }

  async handleKeyUpSearch(event) {
    if (this.ignoreKeyUp.indexOf(event.key) === -1) {
      await this.getDataSelect(this.filterSelect);
    }
  }

  handleChangeSelect(): void {
    if (this.beforeValue !== this.valueSelect) {
      this.beforeValue = this.valueSelect;
      this.valueSelected.emit({
        key: this.selectInterface.key,
        param: this.selectInterface.paramName,
        value: this.valueSelect
      });
    }
  }

  handleChangeSearch(): void {
    this.filterSelect = '';
  }

  returnValueOption(value: any, type: string): string {
    let nowValue = `${value}`;
    if (type === 'boolean') {
      nowValue = (value ? 'Sim' : 'Não')
    } else if (type === 'state') {
      nowValue = mockState[value] || value;
    } else if (type === 'type') {
      nowValue = mockType[value] || value;
    }
    return nowValue;
  }

  clearSelect(): void {
    this.valueSelect = undefined;
    this.filterSelect = '';
    this.handleChangeSelect();
    this.getDataSelect(this.filterSelect);
  }
}
