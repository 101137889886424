import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ToastrModule } from 'ngx-toastr';
import {
  ButtonComponent,
  CardsComponent,
  DividerComponent,
  SelectInfiniteScrollSearchComponent,
  SharedModule as SharedInduxtry,
  SwalComponent,
  TagsComponent,
  TooltipComponent,
  ModalLoadingComponent,
  TextareaInputComponent,
  NavigationComponent,
  TextInputComponent,
  PasswordInputComponent,
  EmptyStateComponent,
  PageSelectComponent,
  MytoastrComponent,
  AvatarComponent,
  TreeInduxtryComponent,
  RadioButtonComponent,
  AccordionInduxtryComponent,
  LinksComponent,
  LoadingComponent,
  TabsInduxtryComponent,
  TabInduxtryComponent,
  GraphicPlotlyComponent,
  DatapickerInputComponent,
  SwitchComponent,
} from '@induxtry/library';
import { SearchComponent } from './components/search/search.component';
import { CardDocumentComponent } from './components/card-document/card-document.component';
import { TagsSpicComponent } from './components/tags-spic/tags-spic.component';
import { SelectSpicComponent } from './components/select-spic/select-spic.component';
import { ModalSpicComponent } from './components/modal-spic/modal-spic.component';
import { InstallPwaComponent } from './components/install-pwa/install-pwa.component';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { IndexedDbService } from './database/indexed-db.service';
import { SyncDataComponent } from './components/sync-data/sync-data.component';
import { ChipsSpicComponent } from './components/chips-spic/chips-spic.component';
import { AvatarSpicComponent } from './components/avatar-spic/avatar-spic.component';
import { ModalInstallIosComponent } from './components/modal-install-ios/modal-install-ios.component';
import { SwiperModule } from 'swiper/angular';
import { SelectInfiniteScrollSpicComponent } from './components/select-infinite-scroll-spic/select-infinite-scroll-spic.component';
import { InputTextSpicComponent } from './components/input-text-spic/input-text-spic.component';
import { AccordionSpicComponent } from './components/accordion-spic/accordion-spic.component';
import { FilterSpicComponent } from './components/filter-spic/filter-spic.component';
import { ButtonFilterSpicComponent } from './components/filter-spic/components/button-filter-spic/button-filter-spic.component';
import { AppliedFiltersComponent } from './components/filter-spic/components/applied-filters/applied-filters.component';
import { OutsideClickDirective } from './components/select-spic/directives/outside-click.directive';
import { GraphicGaugeChartComponent } from './components/graphics/components/graphic-gauge-chart/graphic-gauge-chart.component';
import { CardStatusDashboardComponent } from './components/card-status-dashboard/card-status-dashboard.component';
import { TutorialSpicComponent } from './components/tutorial-spic/tutorial-spic.component';
import { ModalTutorialSpicComponent } from './components/tutorial-spic/components/modal-tutorial-spic/modal-tutorial-spic.component';
import { DynamicTableComponent as DynamicTableSpicComponent } from './components/dynamic-table/dynamic-table.component';
import { SecondFlowchartComponentSpic } from './components/second-flowchart/second-flowchart.component';

const serviceDb = new IndexedDbService();
const database: DBConfig = serviceDb.createDatabase();

@NgModule({
  declarations: [
    SearchComponent,
    CardDocumentComponent,
    TagsSpicComponent,
    SelectSpicComponent,
    ModalSpicComponent,
    InstallPwaComponent,
    SyncDataComponent,
    ChipsSpicComponent,
    AvatarSpicComponent,
    ModalInstallIosComponent,
    SelectInfiniteScrollSpicComponent,
    InputTextSpicComponent,
    AccordionSpicComponent,
    FilterSpicComponent,
    ButtonFilterSpicComponent,
    AppliedFiltersComponent,
    OutsideClickDirective,
    GraphicGaugeChartComponent,
    CardStatusDashboardComponent,
    TutorialSpicComponent,
    ModalTutorialSpicComponent,
    DynamicTableSpicComponent,
    SecondFlowchartComponentSpic
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatMenuModule,
    MatRippleModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    ZXingScannerModule,
    SharedInduxtry,
    ToastrModule.forRoot({
      toastComponent: MytoastrComponent
    }),
    NgxIndexedDBModule.forRoot(database),
    SwiperModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatMenuModule,
    MatRippleModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    ToastrModule,
    MatDialogModule,
    ZXingScannerModule,
    SharedInduxtry,
    ButtonComponent,
    TooltipComponent,
    CardsComponent,
    SelectInfiniteScrollSearchComponent,
    SearchComponent,
    TagsComponent,
    DividerComponent,
    CardDocumentComponent,
    TagsSpicComponent,
    SwalComponent,
    SelectSpicComponent,
    ModalLoadingComponent,
    TextareaInputComponent,
    NavigationComponent,
    TextInputComponent,
    PasswordInputComponent,
    EmptyStateComponent,
    InstallPwaComponent,
    NgxIndexedDBModule,
    SyncDataComponent,
    PageSelectComponent,
    ChipsSpicComponent,
    AvatarComponent,
    AvatarSpicComponent,
    TreeInduxtryComponent,
    SwiperModule,
    RadioButtonComponent,
    AccordionInduxtryComponent,
    LinksComponent,
    AccordionSpicComponent,
    FilterSpicComponent,
    ButtonFilterSpicComponent,
    AppliedFiltersComponent,
    LoadingComponent,
    TabsInduxtryComponent,
    TabInduxtryComponent,
    GraphicPlotlyComponent,
    GraphicGaugeChartComponent,
    DatapickerInputComponent,
    CardStatusDashboardComponent,
    SwitchComponent,
    SecondFlowchartComponentSpic,
    SelectInfiniteScrollSpicComponent,
    TutorialSpicComponent,
    DynamicTableSpicComponent
  ],
  providers: [
    DatePipe,
    DecimalPipe
  ]
})

export class SharedModule {}
